// ** Next Imports
import Image from "next/image";

// ** Image Imports
import review1Img from "../../public/images/review-1.jpg";
import review2Img from "../../public/images/review-2.png";
import review3Img from "../../public/images/review-3.jpg";
import review4Img from "../../public/images/review-4.jpg";
import review5Img from "../../public/images/review-5.jpg";
import review6Img from "../../public/images/review-6.png";
import review7Img from "../../public/images/review-7.jpg";
import review8Img from "../../public/images/review-8.jpg";
import review9Img from "../../public/images/review-9.jpg";

// ** Types
import { SessionStorageNames } from "../types";

interface globalMetaType {
  [key: string]: string;
}

// ** All Data
export const CONTACT_INFO = {
  call: {
    number: "+18777581313",
    text: "(877) 758-1313",
  },
  sms: {
    number: "+14058963561",
    text: "(405) 896-3561",
  },
  email: "hello@ein.team",
};

export const REVIEWS = [
  {
    name: "SUNSHINE LOGISTICS II",
    from: "Google Review",
    img: (
      <Image
        src={review1Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 1"
      />
    ),
    review:
      "If you're looking for an internet service provider that won't overcharge you, let you get started with no credit check, provide incredible customer service and at a low monthly fee this is it! I know the guy who personally runs this business and he is one of the most honest and professional individuals I've ever met. Several of my friends and family now have internet service through this company and they swear by it! Good customer service is hard to come by these days, especially when you have to deal with large internet service providers. If you're like me and really value customer service as well as the service itself, this is it right here!",
  },
  {
    name: "HAYDEN H.",
    from: "Google Review",
    img: (
      <Image
        src={review2Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 2"
      />
    ),
    review:
      "After doing research with many other I decided to go with EIN. I didn&apos;t want to be locked into a contract of others. EIN service is awesome. Customer service are great & they do care about their customers. Will definitely recommend.",
  },
  {
    name: "THERESA W.",
    from: "Facebook Review",
    img: (
      <Image
        src={review3Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 3"
      />
    ),
    review:
      "Easy Internet Now is exactly what you want in your internet service provider. Amazing speed, great for streaming, and wonderful customer service. I like how simple they make everything! I highly recommend it.",
  },
  {
    name: "ALICIA C.",
    from: "Google Review",
    img: (
      <Image
        src={review4Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 4"
      />
    ),
    review:
      "By far the BEST Customer service ever! Their extremely fast to respond, very nice, fast helpful technicians! Am VERY impressed with EIN, don't find many companies who really look after their customers.",
  },
  {
    name: "KAY R.",
    from: "Facebook Review",
    img: (
      <Image
        src={review5Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 5"
      />
    ),
    review:
      "Luv the service. It's reasonable and they work with u. If u have any problems, the response is quick.",
  },
  {
    name: "ANISHA C.",
    from: "Google Review",
    img: (
      <Image
        src={review6Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 6"
      />
    ),
    review:
      "Love, love, love. Just got hooked up today and it's wonderful. I'm glad I gave them a chance I was able to hook up all my devices instantly with no buffering and we have at least 10 devices I see myself being with this company for a long time.",
  },
  {
    name: "PAMELA T",
    from: "Facebook Review",
    img: (
      <Image
        src={review7Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 7"
      />
    ),
    review: "Fast install. High quality service at an excellent price.",
  },
  {
    name: "RHONDA C.",
    from: "Google Review",
    img: (
      <Image
        src={review8Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 8"
      />
    ),
    review:
      "The best internet money can buy. Cut the cord with the big guys and go Easy Internet Now.",
  },
  {
    name: "RAFAEL C.",
    from: "Facebook Review",
    img: (
      <Image
        src={review9Img}
        className="img-fluid"
        placeholder="blur"
        alt="Reviewer Image 9"
      />
    ),
    review:
      "Their internet is reasonably priced and they don't make you wait like a whole bunch of time to get installed and I like that about them too. I've had them for over a year.",
  },
];

export const WHY_CHOOSE_EIN: string[] = [
  "Internet Plans for Every Home or Business Need",
  "Super Fast Speeds",
  "Free WIFI Modem",
  "Nationwide Fiber Network",
  "Flat-Rate Pricing &#8211; No Hidden Fees or Taxes",
  "Unlimited Data and No Throttling",
  "Award Winning Customer Service",
  "No Contract or Credit Checks",
];

export const SUPPORT_LINKS: { [key: string]: { [key: string]: string } } = {
  "Internet Connection": {
    "No Connection / Modem Issues": "/basic-internet-troubleshooting",
    "Slow Speed": "/speedtest",
  },
  "Service Questions": {
    "Move Service to New Address": "/move-order-request",
    "Installation Process": "/installation-steps",
    "Change Account Information": "/change-account-information",
    "Add Authorized User": "/add-authorized-user",
    "Changing your Service Plan / Speed": "/request-speed-plan-change",
    "Cancel Service": "/cancel-service",
  },
  "Billing Questions": {
    "Accessing the Customer Portal": "/accessing-the-customer-portal",
    "Paying an Invoice": "/paying-an-invoice",
    "Quick Pay": "/quick-pay",
    Autopay: "/autopay",
    "Refunds and Credits": "/refunds-and-credits",
  },
  Other: {
    "Referral Program": "/referral-program",
    "Run a Speed Test": "/speedtest",
    "Understanding Internet Speeds": "/understanding-internet-speeds",
    "Contact Us": "/contact-us",
  },
};

export const FAQ_SIDEBAR_LINKS: { [key: string]: string } = {
  Support: "/support",
  "Accessing the Customer Portal": "/accessing-the-customer-portal",
  "Add Authorized User": "/add-authorized-user",
  "Change Account Information": "/change-account-information",
  "Quick Pay": "/quick-pay",
  "Speed Test": "/speedtest",
  "Basic Internet Troubleshootings": "/basic-internet-troubleshooting",
  "Installation Steps": "/installation-steps",
  "Credits & Refunds": "/refunds-and-credits",
  Autopay: "/autopay",
  "Paying an Invoice": "/paying-an-invoice",
  "Referral Program": "/referral-program",
};

export const SESSION_STORAGE_NAMES: SessionStorageNames = {
  JWT: "orderJWT",
  VID: "VID",
};

export const PRICE_LOCK_TEXT =
  "EIN has NEVER Increased the monthly charge on a customer and hopes to NEVER have too, you have a minimum 1 year price lock";

export const DEFAULT_ERROR =
  "We could not check your address at this time, please try again or contact us for more assistance";

export const DEFAULT_CHECKOUT_ERROR =
  "We could not checkout your order at this time, please try again or contact us for more assistance";

export const DEFAULT_COUPON_CODE_ERROR =
  "Coupon Invalid, please check and try again.";

export const globalMetaData: globalMetaType = {
  type: "website",
  siteName: "Easy Internet Now",
  siteUrl: "https://easyinternetnow.com",
  siteLogo: "https://easyinternetnow.com/images/logo.png",
  email: CONTACT_INFO.email,
  fbPublisher: "https://www.facebook.com/easyinternetnow/",
  twitterCreator: "@EasyInternetNow",
  description:
    "Easy plans, all-in price guarantee, no bundling, no credit check and 5-star service from EIN. Because the last thing you should be worried about is your home internet. Hassle Free Internet The connection you need without a contract or credit check! No hidden fees, no bundling or equipment charge ...",
  structuredData: JSON.stringify({
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://easyinternetnow.com/#organization",
        name: "Easy Internet Now",
        url: "https://easyinternetnow.com/",
        sameAs: [
          "https://www.facebook.com/easyinternetnow/",
          "https://www.instagram.com/easyinternetnow/",
          "https://www.linkedin.com/company/16246337/",
          "https://twitter.com/EasyInternetNow",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://easyinternetnow.com/#website-logo",
          inLanguage: "en-US",
          url: "https://easyinternetnow.com/images/logo.png",
          width: 144,
          height: 65,
          caption: "Easy Internet Now",
        },
        image: { "@id": "https://easyinternetnow.com/#website-logo" },
      },
      {
        "@type": "WebPage",
        "@id": "https://easyinternetnow.com/#webpage",
        url: "https://easyinternetnow.com/",
        name: "Home - EIN",
        isPartOf: { "@id": "https://easyinternetnow.com/#website" },
        about: { "@id": "https://easyinternetnow.com/#organization" },
        datePublished: "2017-03-31T05:39:29+00:00",
        dateModified: "2021-09-07T13:25:26+00:00",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://easyinternetnow.com/"],
          },
        ],
      },
    ],
  }),
};
